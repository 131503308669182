// this file is auto generated, do not edit it directly
module.exports = {
  "rootProjectId": "root",
  "stage": "PROD",
  "captchaKey": "6Lf5JUspAAAAAInUmjFLh5pjsEuQ1ngdjiDehMo5",
  "region": "eu-central-1",
  "cosUrl": "api.pinar.retter.io",
  "version": "2.0.28",
  "appUrl": "https://pinar.c.retter.io/",
  "uiBucketName": "pinar.c.retter.io",
  "uiBucketDomainName": "pinar.c.retter.io"
}